<template>
  <Header v-if="isSignin" />
  <div data-layout="horizontal">
    <div class="main-content">
      <div class="page-content">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
    import Header from "./components/header";
    import "firebase/auth";

    export default {
        name: "App",
        components: {
            Header,
        },
        data() {
            return {
                isSignin: false,
                path: ["/", "/signin", "/signup", "/emialVerify"],
            };
        },
        watch: {
            // 监听路由变化
            $route(to, from) {
                // landing页面展示landing自己header
                this.isSignin = !this.path.includes(to.path);
            },
        },
    };
</script>