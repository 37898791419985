import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import SecureLS from "secure-ls"
const ls = new SecureLS({ isCompression: false })

const store = createStore({
    state: {
        mode: 'light',
        userInfo: null
    },
    getters: {
        mode: (state) => {
            return state.mode;
        },
        userInfo: (state) => {
            return state.userInfo;
        }
    },
    actions: {
        mode: (context, mode) => {
            context.commit('mode', mode)
        },
        userInfo: (context, userInfo) => {
            context.commit('userInfo', userInfo)
        }
    },
    mutations: {
        mode: (state, mode) => {
            state.mode = mode
        },
        userInfo: (state, userInfo) => {
            state.userInfo = userInfo
        }
    },
    plugins: [
        createPersistedState({
            key: 'localUserInfo', //儲存在 localStorage 的 key
            storage: {
                getItem: key => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: key => ls.remove(key)
            }
        })
    ]
})

export default store