
export default [{
    path: "/",
    name: "Landing",
    meta: {
        title: "Landing",
        authRequired: false,
    },
    component: () =>
        import("../views/landing.vue"),
},
{
    path: "/signin",
    name: "Signin",
    meta: {
        title: "Signin",
        authRequired: false,
    },
    component: () =>
        import("../views/auth.vue"),
},
{
    path: "/signup",
    name: "Signup",
    meta: {
        title: "Signup",
        authRequired: false,
    },
    component: () =>
        import("../views/auth.vue"),
},
{
    path: "/boards",
    name: "Boards",
    meta: {
        title: "Boards",
        authRequired: true,
    },
    component: () =>
        import("../views/boards.vue"),
},
{
    path: "/home2",
    name: "Home2",
    meta: {
        title: "Home2",
        authRequired: true,
    },
    component: () =>
        import("../views/home2.vue"),
}
];