import { createApp } from 'vue';
import App from './App.vue';
import router from "./router";
import AOS from 'aos';
import 'aos/dist/aos.css';
import i18n from './i18n';
import store from "./state/store";

import BootstrapVue3 from 'bootstrap-vue-3';
import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import Maska from 'maska';

import VueFeather from 'vue-feather';
import Particles from "particles.vue3";

import '@/assets/scss/config/creative/app.scss';
import '@vueform/slider/themes/default.css';
import '@/assets/scss/mermaid.min.css';

import fireBaseConfig from '@/firebase/config'
import firebase from 'firebase/app';

// firebase init
firebase.initializeApp(fireBaseConfig);

AOS.init({
    easing: 'ease-out-back',
    duration: 1000
});

let app = createApp(App);

app.config.errorHandler = (err, vm, info) => {
    console.log('全局异常捕获开始')
    console.log(err)
    console.log(vm)
    console.log(info)
    console.log('全局异常捕获结束')
}

app.use(store)
    .use(router)
    .use(VueApexCharts)
    .use(BootstrapVue3)
    .component(VueFeather.type, VueFeather)
    .use(Maska)
    .use(Particles)
    .use(i18n)
    .use(vClickOutside)
    .mount('#app');



