const config = {
    apiKey: "AIzaSyCmtqeF8U-33qJQK3CHpFF-cYKAex8vSHs",
    authDomain: "otukare.com",
    projectId: "otukare-468b3",
    storageBucket: "otukare-468b3.appspot.com",
    messagingSenderId: "983143000691",
    appId: "1:983143000691:web:2b469cf72bb95ec6ab5806",
    measurementId: "G-E2SBE6FHKY",
}

export default config;