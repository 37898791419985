import { createWebHistory, createRouter } from "vue-router";
import routes from './routes';
import store from '@/state/store';

const router = createRouter({
    history: createWebHistory(),
    routes,
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0, left: 0 };
        }
    },
});

router.beforeEach(async (to, from, next) => {
    // 获取目标页面是否需要登录
    const authRequired = to.matched.some((route) => route.meta.authRequired);

    // 未登录
    if (!store.state.userInfo) {
        if (authRequired) {
            next('/signin');
        }
    } else { // 已登录
        // 已登录状态禁止访问以下页面
        const path = ['/', '/signin', '/signup']
        if (path.includes(to.path)) {
            next('/boards')
        }
    }
    next()
});


export default router;