<script>
import firebase from "firebase/app";
import "firebase/auth";
import { SimpleBar } from "simplebar-vue3";
import { mapState } from "vuex";

export default {
  data() {
    return {};
  },
  components: {
    SimpleBar,
  },

  computed: {
    ...mapState(["userInfo"])
  },
  methods: {
    // 退出登录
    signOut() {
      firebase.auth().signOut().then(() => {
        this.$store.dispatch("userInfo", null);
        this.$router.push("/"); // 回到landing页面
        }).catch((error) => {
          // TODO
          reject(error)
        })
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    toggleDarkMode() {
      const mode = document.documentElement.getAttribute("data-layout-mode") == "dark" ? "light" : "dark";
      this.$store.dispatch("mode", mode);
      document.documentElement.setAttribute("data-layout-mode", mode);
    },
    initTopbarComponents() {
      function updateCartPrice() {
        var currencySign = "$";
        var subtotal = 0;
        Array.from(document.getElementsByClassName("cart-item-price")).forEach(
          function (e) {
            subtotal += parseFloat(e.innerHTML);
          }
        );
        if (document.getElementById("cart-item-total")) {
          document.getElementById("cart-item-total").innerHTML =
            currencySign + subtotal.toFixed(2);
        }
      }

      if (document.getElementsByClassName("dropdown-item-cart")) {
        var dropdownItemCart = document.querySelectorAll(
          ".dropdown-item-cart"
        ).length;
        Array.from(
          document.querySelectorAll(
            "#page-topbar .dropdown-menu-cart .remove-item-btn"
          )
        ).forEach(function (item) {
          item.addEventListener("click", function () {
            dropdownItemCart--;
            this.closest(".dropdown-item-cart").remove();
            Array.from(
              document.getElementsByClassName("cartitem-badge")
            ).forEach(function (e) {
              e.innerHTML = dropdownItemCart;
            });
            updateCartPrice();
            if (document.getElementById("empty-cart")) {
              document.getElementById("empty-cart").style.display =
                dropdownItemCart == 0 ? "block" : "none";
            }
            if (document.getElementById("checkout-elem")) {
              document.getElementById("checkout-elem").style.display =
                dropdownItemCart == 0 ? "none" : "block";
            }
          });
        });
        Array.from(document.getElementsByClassName("cartitem-badge")).forEach(
          function (e) {
            e.innerHTML = dropdownItemCart;
          }
        );
        if (document.getElementById("empty-cart")) {
          document.getElementById("empty-cart").style.display = "none";
        }
        if (document.getElementById("checkout-elem")) {
          document.getElementById("checkout-elem").style.display = "block";
        }
        updateCartPrice();
      }

      // notification messages
      if (document.getElementsByClassName("notification-check")) {
        Array.from(
          document.querySelectorAll(".notification-check input")
        ).forEach(function (element) {
          element.addEventListener("click", function (el) {
            el.target.closest(".notification-item").classList.toggle("active");
          });
        });
      }
    },
  },
  mounted() {
    document.addEventListener("scroll", function () {
      var pageTopbar = document.getElementById("page-topbar");
      if (pageTopbar) {
        document.body.scrollTop >= 50 ||
        document.documentElement.scrollTop >= 50
          ? pageTopbar.classList.add("topbar-shadow")
          : pageTopbar.classList.remove("topbar-shadow");
      }
    });

    // 初期化时的mode
    document.documentElement.setAttribute(
      "data-layout-mode",
      this.$store.state.mode
    );
  },
};
</script>

<template>
  <header id="page-topbar">
    <div class="layout-width">
      <div class="navbar-header">
        <div class="d-flex">
          <!-- LOGO -->
          <div class="navbar-brand-box">
            <router-link to="/" class="logo logo-dark">
              <span class="logo-sm">
                <img src="@/assets/images/logo-dark.png" alt="" height="17" />
              </span>

              <span class="logo-lg">
                <img src="@/assets/images/logo-dark.png" alt="" height="17" />
              </span>
            </router-link>

            <router-link to="/" class="logo logo-light">
              <span class="logo-sm">
                <img src="@/assets/images/logo-light.png" alt="" height="17" />
              </span>
              <span class="logo-lg">
                <img src="@/assets/images/logo-light.png" alt="" height="17" />
              </span>
            </router-link>
          </div>

          <!-- workspqce -->
          <div class="dropdown topbar-head-dropdown ms-1 header-item">
            <button type="button" class="btn btn-ghost-secondary" id="page-header-cart-dropdown" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-haspopup="true" aria-expanded="false">
              ワークスペース
            </button>
            <div class="
                dropdown-menu dropdown-menu-xl dropdown-menu-end
                p-0
                dropdown-menu-cart
              " aria-labelledby="page-header-cart-dropdown">
              <div class="
                  p-3
                  border-top-0 border-start-0 border-end-0 border-dashed border
                ">
                <b-row class="align-items-center">
                  <b-col>
                    <h6 class="m-0 fs-16 fw-semibold">ワークスペース</h6>
                  </b-col>
                  <b-col cols="auto">
                    <b-badge variant="soft-warning" class="badge-soft-info fs-13"><span class="cartitem-badge">7</span> items</b-badge>
                  </b-col>
                </b-row>
              </div>
              <SimpleBar data-simplebar style="max-height: 300px">
                <div class="p-2">
                  <div class="text-center empty-cart" id="empty-cart">
                    <div class="avatar-md mx-auto my-3">
                      <div class="
                          avatar-title
                          bg-soft-secondary
                          text-secondary
                          fs-36
                          rounded-circle
                        ">
                        <i class="bx bx-rocket"></i>
                      </div>
                    </div>
                    <h5 class="mb-3">Create Your Workspace!</h5>
                    <b-link href="/ecommerce/products" class="btn btn-secondary w-md mb-3 w-100">Shop Now</b-link>
                  </div>
                  <!-- <div class="d-block dropdown-item dropdown-item-cart text-wrap px-3 py-2">
                    <div class="d-flex align-items-center">
                      <img src="@/assets/images/products/img-1.png" class="me-3 rounded-circle avatar-sm p-2 bg-light" alt="user-pic">
                      <div class="flex-1">
                        <h6 class="mt-0 mb-1 fs-14">
                          <b-link href="/ecommerce/product-details" class="text-reset">Branded
                            T-Shirts</b-link>
                        </h6>
                        <p class="mb-0 fs-12 text-muted">
                          Quantity: <span>10 x $32</span>
                        </p>
                      </div>
                      <div class="px-2">
                        <h5 class="m-0 fw-normal">$<span class="cart-item-price">320</span></h5>
                      </div>
                      <div class="ps-2">
                        <b-button type="button" variant="ghost-secondary" size="sm" class="btn btn-icon remove-item-btn"><i class="ri-close-fill fs-16"></i></b-button>
                      </div>
                    </div>
                  </div> -->
                </div>
              </SimpleBar>
            </div>
          </div>

          <!-- these days -->
          <div class="dropdown topbar-head-dropdown ms-1 header-item">
            <button type="button" class="btn btn-ghost-secondary" id="page-header-cart-dropdown" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-haspopup="true" aria-expanded="false">
              最近
            </button>
            <div class="
                dropdown-menu dropdown-menu-xl dropdown-menu-end
                p-0
                dropdown-menu-cart
              " aria-labelledby="page-header-cart-dropdown">
              <div class="
                  p-3
                  border-top-0 border-start-0 border-end-0 border-dashed border
                ">
                <b-row class="align-items-center">
                  <b-col>
                    <h6 class="m-0 fs-16 fw-semibold">ワークスペース</h6>
                  </b-col>
                  <b-col cols="auto">
                    <b-badge variant="soft-warning" class="badge-soft-info fs-13"><span class="cartitem-badge">7</span> items</b-badge>
                  </b-col>
                </b-row>
              </div>
              <SimpleBar data-simplebar style="max-height: 300px">
                <div class="p-2">
                  <div class="text-center empty-cart" id="empty-cart">
                    <div class="avatar-md mx-auto my-3">
                      <div class="
                          avatar-title
                          bg-soft-secondary
                          text-secondary
                          fs-36
                          rounded-circle
                        ">
                        <i class="bx bx-rocket"></i>
                      </div>
                    </div>
                    <h5 class="mb-3">Create Your Workspace!</h5>
                    <b-link href="/ecommerce/products" class="btn btn-secondary w-md mb-3 w-100">Shop Now</b-link>
                  </div>
                  <!-- <div class="d-block dropdown-item dropdown-item-cart text-wrap px-3 py-2">
                    <div class="d-flex align-items-center">
                      <img src="@/assets/images/products/img-1.png" class="me-3 rounded-circle avatar-sm p-2 bg-light" alt="user-pic">
                      <div class="flex-1">
                        <h6 class="mt-0 mb-1 fs-14">
                          <b-link href="/ecommerce/product-details" class="text-reset">Branded
                            T-Shirts</b-link>
                        </h6>
                        <p class="mb-0 fs-12 text-muted">
                          Quantity: <span>10 x $32</span>
                        </p>
                      </div>
                      <div class="px-2">
                        <h5 class="m-0 fw-normal">$<span class="cart-item-price">320</span></h5>
                      </div>
                      <div class="ps-2">
                        <b-button type="button" variant="ghost-secondary" size="sm" class="btn btn-icon remove-item-btn"><i class="ri-close-fill fs-16"></i></b-button>
                      </div>
                    </div>
                  </div> -->
                </div>
              </SimpleBar>
            </div>
          </div>
        </div>

        <!-- <span class="position-absolute topbar-badge cartitem-badge fs-10 translate-middle badge rounded-pill bg-info">5</span> -->

        <div class="d-flex align-items-center">
          <!-- notice -->
          <div class="dropdown topbar-head-dropdown ms-1 header-item">
            <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle" id="page-header-notifications-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="bx bx-bell fs-22"></i>
              <span class="
                  position-absolute
                  topbar-badge
                  cartitem-badge
                  fs-10
                  translate-middle
                  badge
                  rounded-pill
                  bg-secondary
                ">5</span>
            </button>
            <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" aria-labelledby="page-header-notifications-dropdown">
              <div class="dropdown-head bg-secondary bg-pattern rounded-top">
                <div class="p-3">
                  <b-row class="align-items-center">
                    <b-col>
                      <h6 class="m-0 fs-16 fw-semibold text-white">
                        Notifications
                      </h6>
                    </b-col>
                    <b-col cols="auto" class="dropdown-tabs">
                      <b-badge variant="soft-light" class="badge-soft-light fs-13">
                        4 New
                      </b-badge>
                    </b-col>
                  </b-row>
                </div>

                <div class="px-2 pt-2">
                  <ul class="nav nav-tabs dropdown-tabs nav-tabs-custom" data-dropdown-tabs="true" id="notificationItemsTab" role="tablist">
                    <li class="nav-item">
                      <b-link class="nav-link active" data-bs-toggle="tab" href="#all-noti-tab" role="tab" aria-selected="false" @click.capture.stop>
                        All (4)
                      </b-link>
                    </li>
                    <li class="nav-item">
                      <b-link class="nav-link" data-bs-toggle="tab" href="#messages-tab" role="tab" aria-selected="true" @click.capture.stop>
                        Messages
                      </b-link>
                    </li>
                    <li class="nav-item">
                      <b-link class="nav-link" data-bs-toggle="tab" href="#alerts-tab" role="tab" aria-selected="false" @click.capture.stop>
                        Alerts
                      </b-link>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="tab-content" id="notificationItemsTabContent">
                <div class="tab-pane fade py-2 ps-2 show active" id="all-noti-tab" role="tabpanel">
                  <SimpleBar data-simplebar style="max-height: 300px" class="pe-2">
                    <div class="
                        text-reset
                        notification-item
                        d-block
                        dropdown-item
                        position-relative
                      ">
                      <div class="d-flex">
                        <div class="avatar-xs me-3">
                          <span class="
                              avatar-title
                              bg-soft-info
                              text-info
                              rounded-circle
                              fs-16
                            ">
                            <i class="bx bx-badge-check"></i>
                          </span>
                        </div>
                        <div class="flex-1">
                          <b-link href="#!" class="stretched-link">
                            <h6 class="mt-0 mb-2 lh-base">
                              Your <b>Elite</b> author Graphic Optimization
                              <span class="text-secondary">reward</span> is
                              ready!
                            </h6>
                          </b-link>
                          <p class="
                              mb-0
                              fs-11
                              fw-medium
                              text-uppercase text-muted
                            ">
                            <span><i class="mdi mdi-clock-outline"></i> Just 30 sec
                              ago</span>
                          </p>
                        </div>
                        <div class="px-2 fs-15">
                          <input class="form-check-input" type="checkbox" />
                        </div>
                      </div>
                    </div>

                    <div class="
                        text-reset
                        notification-item
                        d-block
                        dropdown-item
                        position-relative
                      ">
                      <div class="d-flex">
                        <img src="@/assets/images/users/avatar-2.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic" />
                        <div class="flex-1">
                          <b-link href="#!" class="stretched-link">
                            <h6 class="mt-0 mb-1 fs-13 fw-semibold">
                              Angela Bernier
                            </h6>
                          </b-link>
                          <div class="fs-13 text-muted">
                            <p class="mb-1">
                              Answered to your comment on the cash flow
                              forecast's graph 🔔.
                            </p>
                          </div>
                          <p class="
                              mb-0
                              fs-11
                              fw-medium
                              text-uppercase text-muted
                            ">
                            <span><i class="mdi mdi-clock-outline"></i> 48 min
                              ago</span>
                          </p>
                        </div>
                        <div class="px-2 fs-15">
                          <input class="form-check-input" type="checkbox" />
                        </div>
                      </div>
                    </div>

                    <div class="
                        text-reset
                        notification-item
                        d-block
                        dropdown-item
                        position-relative
                      ">
                      <div class="d-flex">
                        <div class="avatar-xs me-3">
                          <span class="
                              avatar-title
                              bg-soft-danger
                              text-danger
                              rounded-circle
                              fs-16
                            ">
                            <i class="bx bx-message-square-dots"></i>
                          </span>
                        </div>
                        <div class="flex-1">
                          <b-link href="#!" class="stretched-link">
                            <h6 class="mt-0 mb-2 fs-13 lh-base">
                              You have received
                              <b class="text-success">20</b> new messages in the
                              conversation
                            </h6>
                          </b-link>
                          <p class="
                              mb-0
                              fs-11
                              fw-medium
                              text-uppercase text-muted
                            ">
                            <span><i class="mdi mdi-clock-outline"></i> 2 hrs
                              ago</span>
                          </p>
                        </div>
                        <div class="px-2 fs-15">
                          <input class="form-check-input" type="checkbox" />
                        </div>
                      </div>
                    </div>

                    <div class="
                        text-reset
                        notification-item
                        d-block
                        dropdown-item
                        position-relative
                      ">
                      <div class="d-flex">
                        <img src="@/assets/images/users/avatar-8.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic" />
                        <div class="flex-1">
                          <b-link href="#!" class="stretched-link">
                            <h6 class="mt-0 mb-1 fs-13 fw-semibold">
                              Maureen Gibson
                            </h6>
                          </b-link>
                          <div class="fs-13 text-muted">
                            <p class="mb-1">
                              We talked about a project on linkedin.
                            </p>
                          </div>
                          <p class="
                              mb-0
                              fs-11
                              fw-medium
                              text-uppercase text-muted
                            ">
                            <span><i class="mdi mdi-clock-outline"></i> 4 hrs
                              ago</span>
                          </p>
                        </div>
                        <div class="px-2 fs-15">
                          <input class="form-check-input" type="checkbox" />
                        </div>
                      </div>
                    </div>

                    <div class="my-3 text-center">
                      <b-button type="button" variant="soft-success">
                        View All Notifications
                        <i class="ri-arrow-right-line align-middle"></i>
                      </b-button>
                    </div>
                  </SimpleBar>
                </div>

                <div class="tab-pane fade py-2 ps-2" id="messages-tab" role="tabpanel" aria-labelledby="messages-tab">
                  <SimpleBar data-simplebar style="max-height: 300px" class="pe-2">
                    <div class="text-reset notification-item d-block dropdown-item">
                      <div class="d-flex">
                        <img src="@/assets/images/users/avatar-3.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic" />
                        <div class="flex-1">
                          <b-link href="#!" class="stretched-link">
                            <h6 class="mt-0 mb-1 fs-13 fw-semibold">
                              James Lemire
                            </h6>
                          </b-link>
                          <div class="fs-13 text-muted">
                            <p class="mb-1">
                              We talked about a project on linkedin.
                            </p>
                          </div>
                          <p class="
                              mb-0
                              fs-11
                              fw-medium
                              text-uppercase text-muted
                            ">
                            <span><i class="mdi mdi-clock-outline"></i> 30 min
                              ago</span>
                          </p>
                        </div>
                        <div class="px-2 fs-15">
                          <input class="form-check-input" type="checkbox" />
                        </div>
                      </div>
                    </div>

                    <div class="text-reset notification-item d-block dropdown-item">
                      <div class="d-flex">
                        <img src="@/assets/images/users/avatar-2.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic" />
                        <div class="flex-1">
                          <b-link href="#!" class="stretched-link">
                            <h6 class="mt-0 mb-1 fs-13 fw-semibold">
                              Angela Bernier
                            </h6>
                          </b-link>
                          <div class="fs-13 text-muted">
                            <p class="mb-1">
                              Answered to your comment on the cash flow
                              forecast's graph 🔔.
                            </p>
                          </div>
                          <p class="
                              mb-0
                              fs-11
                              fw-medium
                              text-uppercase text-muted
                            ">
                            <span><i class="mdi mdi-clock-outline"></i> 2 hrs
                              ago</span>
                          </p>
                        </div>
                        <div class="px-2 fs-15">
                          <input class="form-check-input" type="checkbox" />
                        </div>
                      </div>
                    </div>

                    <div class="text-reset notification-item d-block dropdown-item">
                      <div class="d-flex">
                        <img src="@/assets/images/users/avatar-6.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic" />
                        <div class="flex-1">
                          <b-link href="#!" class="stretched-link">
                            <h6 class="mt-0 mb-1 fs-13 fw-semibold">
                              Kenneth Brown
                            </h6>
                          </b-link>
                          <div class="fs-13 text-muted">
                            <p class="mb-1">
                              Mentionned you in his comment on 📃 invoice
                              #12501.
                            </p>
                          </div>
                          <p class="
                              mb-0
                              fs-11
                              fw-medium
                              text-uppercase text-muted
                            ">
                            <span><i class="mdi mdi-clock-outline"></i> 10 hrs
                              ago</span>
                          </p>
                        </div>
                        <div class="px-2 fs-15">
                          <input class="form-check-input" type="checkbox" />
                        </div>
                      </div>
                    </div>

                    <div class="text-reset notification-item d-block dropdown-item">
                      <div class="d-flex">
                        <img src="@/assets/images/users/avatar-8.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic" />
                        <div class="flex-1">
                          <b-link href="#!" class="stretched-link">
                            <h6 class="mt-0 mb-1 fs-13 fw-semibold">
                              Maureen Gibson
                            </h6>
                          </b-link>
                          <div class="fs-13 text-muted">
                            <p class="mb-1">
                              We talked about a project on linkedin.
                            </p>
                          </div>
                          <p class="
                              mb-0
                              fs-11
                              fw-medium
                              text-uppercase text-muted
                            ">
                            <span><i class="mdi mdi-clock-outline"></i> 3 days
                              ago</span>
                          </p>
                        </div>
                        <div class="px-2 fs-15">
                          <input class="form-check-input" type="checkbox" />
                        </div>
                      </div>
                    </div>

                    <div class="my-3 text-center">
                      <b-button type="button" variant="soft-success">
                        View All Messages
                        <i class="ri-arrow-right-line align-middle"></i>
                      </b-button>
                    </div>
                  </SimpleBar>
                </div>

                <div class="tab-pane fade p-4" id="alerts-tab" role="tabpanel" aria-labelledby="alerts-tab">
                  <div class="w-25 w-sm-50 pt-3 mx-auto">
                    <img src="@/assets/images/svg/bell.svg" class="img-fluid" alt="user-pic" />
                  </div>
                  <div class="text-center pb-5 mt-2">
                    <h6 class="fs-18 fw-semibold lh-base">
                      Hey! You have no any notifications
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- question -->
          <div class="dropdown topbar-head-dropdown ms-1 header-item">
            <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle" id="page-header-notifications-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="bx bx-help-circle fs-22"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" aria-labelledby="page-header-notifications-dropdown">
              <div class="dropdown-head bg-secondary bg-pattern rounded-top">
                <div class="p-3">
                  <b-row class="align-items-center">
                    <b-col>
                      <h6 class="m-0 fs-16 fw-semibold text-white">
                        Notifications
                      </h6>
                    </b-col>
                    <b-col cols="auto" class="dropdown-tabs">
                      <b-badge variant="soft-light" class="badge-soft-light fs-13">
                        4 New
                      </b-badge>
                    </b-col>
                  </b-row>
                </div>

                <div class="px-2 pt-2">
                  <ul class="nav nav-tabs dropdown-tabs nav-tabs-custom" data-dropdown-tabs="true" id="notificationItemsTab" role="tablist">
                    <li class="nav-item">
                      <b-link class="nav-link active" data-bs-toggle="tab" href="#all-noti-tab" role="tab" aria-selected="false" @click.capture.stop>
                        All (4)
                      </b-link>
                    </li>
                    <li class="nav-item">
                      <b-link class="nav-link" data-bs-toggle="tab" href="#messages-tab" role="tab" aria-selected="true" @click.capture.stop>
                        Messages
                      </b-link>
                    </li>
                    <li class="nav-item">
                      <b-link class="nav-link" data-bs-toggle="tab" href="#alerts-tab" role="tab" aria-selected="false" @click.capture.stop>
                        Alerts
                      </b-link>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="tab-content" id="notificationItemsTabContent">
                <div class="tab-pane fade py-2 ps-2 show active" id="all-noti-tab" role="tabpanel">
                  <SimpleBar data-simplebar style="max-height: 300px" class="pe-2">
                    <div class="
                        text-reset
                        notification-item
                        d-block
                        dropdown-item
                        position-relative
                      ">
                      <div class="d-flex">
                        <div class="avatar-xs me-3">
                          <span class="
                              avatar-title
                              bg-soft-info
                              text-info
                              rounded-circle
                              fs-16
                            ">
                            <i class="bx bx-badge-check"></i>
                          </span>
                        </div>
                        <div class="flex-1">
                          <b-link href="#!" class="stretched-link">
                            <h6 class="mt-0 mb-2 lh-base">
                              Your <b>Elite</b> author Graphic Optimization
                              <span class="text-secondary">reward</span> is
                              ready!
                            </h6>
                          </b-link>
                          <p class="
                              mb-0
                              fs-11
                              fw-medium
                              text-uppercase text-muted
                            ">
                            <span><i class="mdi mdi-clock-outline"></i> Just 30 sec
                              ago</span>
                          </p>
                        </div>
                        <div class="px-2 fs-15">
                          <input class="form-check-input" type="checkbox" />
                        </div>
                      </div>
                    </div>

                    <div class="
                        text-reset
                        notification-item
                        d-block
                        dropdown-item
                        position-relative
                      ">
                      <div class="d-flex">
                        <img src="@/assets/images/users/avatar-2.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic" />
                        <div class="flex-1">
                          <b-link href="#!" class="stretched-link">
                            <h6 class="mt-0 mb-1 fs-13 fw-semibold">
                              Angela Bernier
                            </h6>
                          </b-link>
                          <div class="fs-13 text-muted">
                            <p class="mb-1">
                              Answered to your comment on the cash flow
                              forecast's graph 🔔.
                            </p>
                          </div>
                          <p class="
                              mb-0
                              fs-11
                              fw-medium
                              text-uppercase text-muted
                            ">
                            <span><i class="mdi mdi-clock-outline"></i> 48 min
                              ago</span>
                          </p>
                        </div>
                        <div class="px-2 fs-15">
                          <input class="form-check-input" type="checkbox" />
                        </div>
                      </div>
                    </div>

                    <div class="
                        text-reset
                        notification-item
                        d-block
                        dropdown-item
                        position-relative
                      ">
                      <div class="d-flex">
                        <div class="avatar-xs me-3">
                          <span class="
                              avatar-title
                              bg-soft-danger
                              text-danger
                              rounded-circle
                              fs-16
                            ">
                            <i class="bx bx-message-square-dots"></i>
                          </span>
                        </div>
                        <div class="flex-1">
                          <b-link href="#!" class="stretched-link">
                            <h6 class="mt-0 mb-2 fs-13 lh-base">
                              You have received
                              <b class="text-success">20</b> new messages in the
                              conversation
                            </h6>
                          </b-link>
                          <p class="
                              mb-0
                              fs-11
                              fw-medium
                              text-uppercase text-muted
                            ">
                            <span><i class="mdi mdi-clock-outline"></i> 2 hrs
                              ago</span>
                          </p>
                        </div>
                        <div class="px-2 fs-15">
                          <input class="form-check-input" type="checkbox" />
                        </div>
                      </div>
                    </div>

                    <div class="
                        text-reset
                        notification-item
                        d-block
                        dropdown-item
                        position-relative
                      ">
                      <div class="d-flex">
                        <img src="@/assets/images/users/avatar-8.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic" />
                        <div class="flex-1">
                          <b-link href="#!" class="stretched-link">
                            <h6 class="mt-0 mb-1 fs-13 fw-semibold">
                              Maureen Gibson
                            </h6>
                          </b-link>
                          <div class="fs-13 text-muted">
                            <p class="mb-1">
                              We talked about a project on linkedin.
                            </p>
                          </div>
                          <p class="
                              mb-0
                              fs-11
                              fw-medium
                              text-uppercase text-muted
                            ">
                            <span><i class="mdi mdi-clock-outline"></i> 4 hrs
                              ago</span>
                          </p>
                        </div>
                        <div class="px-2 fs-15">
                          <input class="form-check-input" type="checkbox" />
                        </div>
                      </div>
                    </div>

                    <div class="my-3 text-center">
                      <b-button type="button" variant="soft-success">
                        View All Notifications
                        <i class="ri-arrow-right-line align-middle"></i>
                      </b-button>
                    </div>
                  </SimpleBar>
                </div>

                <div class="tab-pane fade py-2 ps-2" id="messages-tab" role="tabpanel" aria-labelledby="messages-tab">
                  <SimpleBar data-simplebar style="max-height: 300px" class="pe-2">
                    <div class="text-reset notification-item d-block dropdown-item">
                      <div class="d-flex">
                        <img src="@/assets/images/users/avatar-3.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic" />
                        <div class="flex-1">
                          <b-link href="#!" class="stretched-link">
                            <h6 class="mt-0 mb-1 fs-13 fw-semibold">
                              James Lemire
                            </h6>
                          </b-link>
                          <div class="fs-13 text-muted">
                            <p class="mb-1">
                              We talked about a project on linkedin.
                            </p>
                          </div>
                          <p class="
                              mb-0
                              fs-11
                              fw-medium
                              text-uppercase text-muted
                            ">
                            <span><i class="mdi mdi-clock-outline"></i> 30 min
                              ago</span>
                          </p>
                        </div>
                        <div class="px-2 fs-15">
                          <input class="form-check-input" type="checkbox" />
                        </div>
                      </div>
                    </div>

                    <div class="text-reset notification-item d-block dropdown-item">
                      <div class="d-flex">
                        <img src="@/assets/images/users/avatar-2.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic" />
                        <div class="flex-1">
                          <b-link href="#!" class="stretched-link">
                            <h6 class="mt-0 mb-1 fs-13 fw-semibold">
                              Angela Bernier
                            </h6>
                          </b-link>
                          <div class="fs-13 text-muted">
                            <p class="mb-1">
                              Answered to your comment on the cash flow
                              forecast's graph 🔔.
                            </p>
                          </div>
                          <p class="
                              mb-0
                              fs-11
                              fw-medium
                              text-uppercase text-muted
                            ">
                            <span><i class="mdi mdi-clock-outline"></i> 2 hrs
                              ago</span>
                          </p>
                        </div>
                        <div class="px-2 fs-15">
                          <input class="form-check-input" type="checkbox" />
                        </div>
                      </div>
                    </div>

                    <div class="text-reset notification-item d-block dropdown-item">
                      <div class="d-flex">
                        <img src="@/assets/images/users/avatar-6.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic" />
                        <div class="flex-1">
                          <b-link href="#!" class="stretched-link">
                            <h6 class="mt-0 mb-1 fs-13 fw-semibold">
                              Kenneth Brown
                            </h6>
                          </b-link>
                          <div class="fs-13 text-muted">
                            <p class="mb-1">
                              Mentionned you in his comment on 📃 invoice
                              #12501.
                            </p>
                          </div>
                          <p class="
                              mb-0
                              fs-11
                              fw-medium
                              text-uppercase text-muted
                            ">
                            <span><i class="mdi mdi-clock-outline"></i> 10 hrs
                              ago</span>
                          </p>
                        </div>
                        <div class="px-2 fs-15">
                          <input class="form-check-input" type="checkbox" />
                        </div>
                      </div>
                    </div>

                    <div class="text-reset notification-item d-block dropdown-item">
                      <div class="d-flex">
                        <img src="@/assets/images/users/avatar-8.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic" />
                        <div class="flex-1">
                          <b-link href="#!" class="stretched-link">
                            <h6 class="mt-0 mb-1 fs-13 fw-semibold">
                              Maureen Gibson
                            </h6>
                          </b-link>
                          <div class="fs-13 text-muted">
                            <p class="mb-1">
                              We talked about a project on linkedin.
                            </p>
                          </div>
                          <p class="
                              mb-0
                              fs-11
                              fw-medium
                              text-uppercase text-muted
                            ">
                            <span><i class="mdi mdi-clock-outline"></i> 3 days
                              ago</span>
                          </p>
                        </div>
                        <div class="px-2 fs-15">
                          <input class="form-check-input" type="checkbox" />
                        </div>
                      </div>
                    </div>

                    <div class="my-3 text-center">
                      <b-button type="button" variant="soft-success">
                        View All Messages
                        <i class="ri-arrow-right-line align-middle"></i>
                      </b-button>
                    </div>
                  </SimpleBar>
                </div>

                <div class="tab-pane fade p-4" id="alerts-tab" role="tabpanel" aria-labelledby="alerts-tab">
                  <div class="w-25 w-sm-50 pt-3 mx-auto">
                    <img src="@/assets/images/svg/bell.svg" class="img-fluid" alt="user-pic" />
                  </div>
                  <div class="text-center pb-5 mt-2">
                    <h6 class="fs-18 fw-semibold lh-base">
                      Hey! You have no any notifications
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--mode change button-->
          <div class="ms-1 header-item d-none d-sm-flex">
            <b-button type="button" variant="ghost-secondary" class="btn-icon btn-topbar rounded-circle light-dark-mode" @click="toggleDarkMode">
              <i class="bx bx-moon fs-22"></i>
            </b-button>
          </div>

          <div class="dropdown header-item">
            <button type="button" class="btn" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span class="d-flex align-items-center">
                <img class="rounded-circle header-profile-user" :src="userInfo?.photoURL" :alt="userInfo?.photoURL" />
              </span>
              <!-- Avatar With Content -->

            </button>
            <div class="dropdown-menu dropdown-menu-end">

              <h6 class="dropdown-header pb-0 fs-5">Welcome</h6>
              <h6 class="dropdown-header pt-0 fs-6">{{userInfo?.displayName}} !</h6>

              <router-link class="dropdown-item" to="/pages/profile">
                <i class="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
                <span class="align-middle">Profile</span>
              </router-link>

              <router-link class="dropdown-item" to="/chat">
                <i class="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i>
                <span class="align-middle">Messages</span>
              </router-link>

              <router-link class="dropdown-item" to="/apps/tasks-kanban">
                <i class="
                    mdi mdi-calendar-check-outline
                    text-muted
                    fs-16
                    align-middle
                    me-1
                  "></i>
                <span class="align-middle">Taskboard</span>
              </router-link>
              <router-link class="dropdown-item" to="/pages/faqs"><i class="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i>
                <span class="align-middle">Help</span>
              </router-link>
              <div class="dropdown-divider"></div>
              <router-link class="dropdown-item" to="/pages/profile"><i class="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i>
                <span class="align-middle">Balance : <b>$5971.67</b></span>
              </router-link>
              <router-link class="dropdown-item" to="/pages/profile-setting">
                <b-badge variant="soft-success" class="bg-soft-success text-success mt-1 float-end">New
                </b-badge>
                <i class="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i>
                <span class="align-middle">Settings</span>
              </router-link>
              <router-link class="dropdown-item" to="/auth/lockscreen-basic"><i class="mdi mdi-lock text-muted fs-16 align-middle me-1"></i>
                <span class="align-middle">Lock screen</span>
              </router-link>

              <b-link class="dropdown-item" @click="signOut()">
                <i class="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>
                <span class="align-middle">Logout</span>
              </b-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template >